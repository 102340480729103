import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import { graphql } from "gatsby"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { getPostObj } from "../utils/utils"

import SEO from "../components/seo"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

const PatientInstructionsPage = ({ pageContext, location, data }) => {
  const postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  const { post, language } = postObj

  let instructionsData

  switch (language) {
    case "en":
      instructionsData = data.allInstructionsJson.nodes
      break
    case "es":
      instructionsData = data.allSpanishInstructionsJson.nodes
      break
    default:
      instructionsData = data.allInstructionsJson.nodes
  }

  function sortArchive(archiveArray) {
    archiveArray.sort((a, b) => (a.order > b.order ? 1 : -1))
  }

  sortArchive(instructionsData)

  const hasPre = instructionsData.find(page => page.preOrPost === "pre")
  const hasPost = instructionsData.find(page => page.preOrPost === "post")

  const instructionsPre = instructionsData.map(instruction => {
    if (instruction.preOrPost === "pre") {
      return (
        <InstructionItem
          language={instruction.language || language}
          preOrPost={instruction.preOrPost}
          url={instruction.title}
          name={instruction.name}
          youtube={instruction.youtube}
          instructionBlockHeading={instruction.instructionBlockHeading}
        />
      )
    }
  })

  const instructionsPostsWithVideo = instructionsData.map(instruction => {
    if (instruction.preOrPost === "post" && instruction.youtube) {
      return (
        <InstructionItem
          language={instruction.language || language}
          preOrPost={instruction.preOrPost}
          url={instruction.title}
          name={instruction.name}
          youtube={instruction.youtube}
          instructionBlockHeading={instruction.instructionBlockHeading}
        />
      )
    }
  })

  const instructionsPostsNoVideo = instructionsData.map(instruction => {
    if (instruction.preOrPost === "post" && !instruction.youtube) {
      return (
        <InstructionItem
          language={instruction.language || language}
          preOrPost={instruction.preOrPost}
          url={instruction.title}
          name={instruction.name}
          youtube={instruction.youtube}
          instructionBlockHeading={instruction.instructionBlockHeading}
        />
      )
    }
  })

  return (
    <SharedStateProvider>
      <Layout language="en">
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          lang={language}
        />
        <div className="joshua-tree-content instructions-query">
          <div className="columns top-section text-section">
            <div className="column">
              <div className="columns is-mobile">
                <div className="column is-4" />
                <div className="column">
                  <div>
                    <h1 style={{ marginTop: 0 }}>{post.heading}</h1>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-4" />
                <div
                  className="column is-16"
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.topBlurb)
                  )}
                />
                <div className="column is-4" />
              </div>
            </div>
          </div>
          <section className="body-sections section instructions-body">
            {hasPre && (
              <>
                <div className="columns operative-text-section">
                  <div className="column">
                    <div className="columns">
                      <div className="column is-4" />
                      <div className="column pre-post-text">
                        <h4>{post.preOpSmallHeading}</h4>
                        <h3>{post.preOpBigHeading}</h3>
                        <div
                          dangerouslySetInnerHTML={createHtml(
                            converter.makeHtml(post.preOpBlurb)
                          )}
                        />
                      </div>
                      <div className="column is-6" />
                    </div>
                  </div>
                </div>
                <div className="pre-op-instructions-wrapper">
                  {instructionsPre}
                </div>
              </>
            )}

             {hasPost && (
              <>
                <div className="columns operative-text-section">
                  <div className="column">
                    <div className="columns">
                      <div className="column is-4" />
                      {/* <div className="column pre-post-text">
                        <h4>{post.postOpSmallHeading}</h4>
                        <h3>{post.postOpBigHeading}</h3>
                        <div
                          dangerouslySetInnerHTML={createHtml(
                            converter.makeHtml(post.postOpBlurb)
                          )}
                        />
                      </div> */}
                      <div className="column is-6" />
                    </div>
                  </div>
                </div>
                <div className="post-op-instructions-wrapper">
                  {instructionsPostsWithVideo}
                  {instructionsPostsNoVideo}
                </div>
              </>
            )} 
            {/* <div>
              <HeadingParagraphButton
                sideColumnIs={4}
                heading={post.getStarted.heading}
                paragraph={post.getStarted.blurb}
                buttonText={post.getStarted.buttonText}
                buttonUrl={post.getStarted.href}
              />
            </div> */}
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

function InstructionItem(props) {
  const { youtube } = props

  let subheading

  if (props.language === "es") {
    if (props.preOrPost === "pre") {
      subheading = "Preoperatorio"
    } else {
      subheading = "Postoperatorio"
    }
  } else if (props.preOrPost === "pre") {
    subheading = "Pre-Operative"
  } else {
    subheading = "Post-Operative"
  }

  return (
    <div className="columns instructions-block-wrapper">
      <div className="column is-3" />
      <div className="column instructions-block">
        <div className="left-is-1 right-is-1">
          <div className="columns is-vcentered">
            <div className="column">
              {/* <h4 className="operative-subheading">{subheading}</h4> */}
              <h5 className="operative-heading">
                <MarkdownViewer markdown={props.instructionBlockHeading} />
              </h5>
            </div>
            <div className="column">
              <div className="button-group-wrapper is-centered-mobile">
                <div className="button-group">
                  {youtube && (
                    <TheaterVideo
                      videoUrl={`http://youtube.com/watch?v=${youtube}`}
                      playing
                      onlyButton
                      language={props.language}
                      buttonClass="contained"
                    />
                  )}

                  <Button
                    contained
                    buttonText={
                      props.language === "es" ? "Leer Más" : "Learn More"
                    }
                    href={`/${props.url}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-3" />
    </div>
  )
}

export const pageQuery = graphql`
  query instructionsEs($title: String!) {
    allInstructionsJson {
      nodes {
        title
        name
        preOrPost
        youtube
        instructionBlockHeading
        order
      }
    }
    allSpanishInstructionsJson {
      nodes {
        title
        name
        preOrPost
        youtube
        instructionBlockHeading
        order
        language
      }
    }
    allInstructionsJson {
      nodes {
        title
        name
        preOrPost
        youtube
        instructionBlockHeading
        order
      }
    }
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        preOpBlurb
        postOpBlurb
        preOpSmallHeading
        preOpBigHeading
        postOpBigHeading
        postOpSmallHeading
        getStarted {
          blurb
          buttonText
          heading
          href
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        preOpBlurb
        postOpBlurb
        preOpSmallHeading
        preOpBigHeading
        postOpBigHeading
        postOpSmallHeading
        getStarted {
          blurb
          buttonText
          heading
          href
        }
      }
    }
  }
`

export default PatientInstructionsPage
